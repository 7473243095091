import { colorify } from 'lottie-colorify'
import { memo } from 'react'
import LottieAnimation from '../LottieAnimation'
import Providers from 'd2/providers/standalone'
import animationData from './animationData.json'
import useStyles from './styles'
import useTheme from 'd2/hooks/useTheme'

export type OwnProps = {
  fillHeight?: boolean | null,
  size?: number,
  testID?: string
}

type Props = OwnProps

const LoadingSpinner = memo<Props>(({
  fillHeight,
  size = 75,
}) => {
  const { classes, cx } = useStyles()
  const theme = useTheme()
  const themedAnimationData: object = colorify([theme.branding?.secondaryColor], animationData)

  return (<div
    className={cx({
      [classes.containerFillHeight]: fillHeight,
    })}
  >
    <div
      className={cx({
        [classes.containerInnerFillHeight]: fillHeight,
      })}
    >
      <LottieAnimation
        animationData={themedAnimationData}
        className={classes.spinner}
        height={size}
        startDelayMs={1000}
        width={size}
      />
    </div>
  </div>)
})

LoadingSpinner.displayName = 'LoadingSpinner'

export const ComponentWithProviders = memo<EO>(() => (<Providers>
  <LoadingSpinner />
</Providers>))

ComponentWithProviders.displayName = 'LoadingSpinner'

export default LoadingSpinner
