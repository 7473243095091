import {
  memo,
  useCallback,
  useEffect,
  useRef,
} from 'react'
import lottie from 'lottie-web'

export interface Props {
  animationData: object
  className?: string
  width: number
  height: number
  startDelayMs?: number
}

// If specs fail after making changes in this file, you may need to update `jest.mock('lottie-web')` within `jest-setup.js`

export const LottieAnimation = memo(({
  animationData,
  className,
  height,
  startDelayMs = 0,
  width,
}: Props) => {
  const element = useRef<HTMLDivElement>(null)
  const lottieInstance = useRef<any>()
  const timeoutRef = useRef<NodeJS.Timeout>()

  const playAnimation = useCallback(() => {
    if (lottieInstance.current || !element.current) return

    lottieInstance.current = lottie.loadAnimation({
      animationData,
      container: element.current!,
      renderer: 'svg',
    })
  }, [animationData, element])

  const playAnimationAfterDelay = useCallback(() => {
    timeoutRef.current = setTimeout(() => {
      playAnimation()
    }, startDelayMs)

    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current)
    }
  }, [playAnimation, startDelayMs])

  useEffect(() => {
    if (!element.current) return

    if (startDelayMs > 0) {
      playAnimationAfterDelay()
    } else {
      playAnimation()
    }

    return () => {
      lottieInstance.current?.destroy()
      lottieInstance.current = null // Must explicitly be set to null, otherwise a bug can occur where duplicate animations will load on window resize
    }
  }, [playAnimation, playAnimationAfterDelay, startDelayMs])

  return (<div
    className={className}
    ref={element}
    style={{
      height,
      width,
    }}
  />)
})

LottieAnimation.displayName = 'LottieAnimation'

export default LottieAnimation
