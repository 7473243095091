import { SPACING_OCTUPLE } from 'd2/constants'
import { px } from 'd2/utils/style'
import makeStyles from 'd2/hooks/tss/makeStyles'

const styles = () => ({
  containerFillHeight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: px(SPACING_OCTUPLE * 2),
  },
  containerInnerFillHeight: {
    flex: '0',
  },
  spinner: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
} as const)

export default makeStyles({
  name: 'LoadingSpinner',
})(styles)
